import GoogleAPI from '../../../store/globalReducers/Google/GoogleApi';

import { Colors } from '../../../helpers/globals/Constans';
import { ClusterLevel, MapTypeId } from '../../../types';
import { MAP_TYPE_ID } from '../constants/MapConstants';

const getMapTypes = (): typeof MAP_TYPE_ID => {
  const {
    google: { maps: googleMaps },
  } = GoogleAPI();

  return googleMaps.MapTypeId;
};

const {
  havelockBlue,
  brilliantRose,
  libertyGreen,
  beigeRed,
  green,
  yellowChenin,
} = Colors;

const getShapesColorsMap = (): Record<
  MapTypeId,
  Partial<Record<ClusterLevel, string>>
> => {
  const { ROADMAP, HYBRID } = getMapTypes();

  return {
    // ROADMAP should be set as a default view for maps
    [ROADMAP]: {
      emirate: havelockBlue.value,
      governorate: brilliantRose.value,
      city: libertyGreen.value,
      district: beigeRed.value,
    },
    [HYBRID]: {
      city: green.value,
      district: yellowChenin.value,
    },
  };
};

const getOutlineColor = (mapType: MapTypeId, areaType: ClusterLevel) => {
  const shapesColors = getShapesColorsMap();
  const { HYBRID } = getMapTypes();

  const color = shapesColors[mapType][areaType];

  return color || shapesColors[HYBRID][areaType];
};

export default getOutlineColor;
