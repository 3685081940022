import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { debounce } from 'throttle-debounce';

import CONFIG from '../../../../config/config';
import MapViewStyles from './MapViewStyles';
import MapMarkers from '../Markers/Markers';
import {
  getBoundsFromMap,
  getChosenRegionData,
  getMinZoomLevel,
} from '../../../../helpers/MapHelper';
import { isAdmin } from '../../../../helpers/UserHelper';
import {
  googleMapEvents,
  mapEventState,
  riyadhRegionString,
} from '../../../../helpers/globals/Constans.ts';
import MapExtensionsContainer from '../../containers/MapExtensionsContainer';
import { setMapShape } from '../../actions/MapActions';

import './mapView.scss';
import MapLoader from '../LoadingMap/Loader';
import OnboardingContainer from '../../../Onboarding/containers/OnboardingContainer';
import store from '../../../../store';

const EVENTS_DEBOUNCE_TIME = 200;
const ANIMATION_DELAY = 150;
const ANIMATION_LAST_STEP_DELAY = 250;
const INIT_MAP_DATA_LISTENERS_DELAY = 500;

export const MapView = () => <Map />;

export class Map extends React.Component {
  constructor(props) {
    super(props);

    this.googleMaps = props.google.maps;
    // eslint-disable-next-line react/no-unused-class-component-methods -- uses outside
    this.geocoder = new this.googleMaps.Geocoder();
    this.mapEventCallCounter = 0;
    this.breadcrumbEvents = {};
    this.state = {
      loader: true,
    };
  }

  componentDidMount() {
    const { actions, chosenRegion } = this.props;
    actions.setUser();
    this.loadMap();

    if (chosenRegion) {
      this.moveToChosenRegion();
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return !isEqual(this.props, prevProps) || !isEqual(this.state, prevState);
  }

  componentDidUpdate(prevProps) {
    const { google, mapOptions, actions, clusters, chosenRegion, businesses } =
      this.props;

    if (chosenRegion !== prevProps.chosenRegion) {
      if (prevProps.chosenRegion) {
        this.changeChosenRegion();
      } else {
        this.moveToChosenRegion();
      }
    }

    if (prevProps.google !== google) {
      this.loadMap();
    }

    if (prevProps.mapOptions.center !== mapOptions.center) {
      this.recenterMap();
    }

    const { zoom, mapTypeId } = mapOptions;

    if (prevProps.mapOptions.zoom !== zoom) {
      this.changeZoom(zoom);
    }

    if (prevProps.mapOptions.mapTypeId !== mapTypeId) {
      this.map.setMapTypeId(mapTypeId);
    }

    if (prevProps.mapOptions.viewport !== mapOptions.viewport) {
      this.map.fitBounds(mapOptions.viewport);
    }

    if (prevProps.businesses !== businesses) {
      actions.setInfoWindowOnMap();
    }

    if (prevProps.clusters.enabled !== clusters.enabled) {
      actions.getBusinesses();
    }
  }

  handleOrientationChange() {
    this.recenterMap();
  }

  animateMoveToChosenRegion = (targetCenter, currentCenter, delta) => {
    const {
      google: { maps },
    } = this.props;

    const { lng: targetLng, lat: targetLat } = targetCenter;
    const { lng: currLng, lat: currLat } = currentCenter;
    const { lng: deltaLng, lat: deltaLat } = delta;

    const currentZoomLevel = this.map.getZoom();

    if (
      Math.abs(targetLng - currLng) <= Math.abs(deltaLng) ||
      Math.abs(targetLat - currLat) <= Math.abs(deltaLat)
    ) {
      const newCenter = new maps.LatLng(targetLat, targetLng);

      setTimeout(() => {
        this.map.panTo(newCenter);
        this.map.setZoom(currentZoomLevel + 1);
        this.setNewMapOptions();
      }, ANIMATION_DELAY);
    } else {
      const newLat = currLat + deltaLat;
      const newLng = currLng + deltaLng;

      const newCenter = new maps.LatLng(newLat, newLng);

      setTimeout(() => {
        this.map.panTo(newCenter);
        this.map.setZoom(currentZoomLevel + 1);
        this.animateMoveToChosenRegion(
          targetCenter,
          { lng: newLng, lat: newLat },
          delta,
        );
      }, ANIMATION_DELAY);
    }
  };

  setNewMapOptions = () => {
    setTimeout(() => {
      const {
        chosenRegion,
        actions: { setMapOptions },
      } = this.props;
      const { chosenRegionCenter } = getChosenRegionData(chosenRegion);
      const chosenRegionZoomLevel = getMinZoomLevel(chosenRegion);
      const newMapOptions = {
        center: chosenRegionCenter,
        zoom: chosenRegionZoomLevel,
      };
      const mapOptionsAfterAnimation = {
        gestureHandling: 'auto',
        minZoom: chosenRegionZoomLevel,
        ...newMapOptions,
      };
      setMapOptions(mapOptionsAfterAnimation);
      this.map.setOptions(mapOptionsAfterAnimation);
      this.initBreadcrumbListeners();
    }, INIT_MAP_DATA_LISTENERS_DELAY);
  };

  changeChosenRegion = () => {
    const {
      actions: { setMapOptions },
    } = this.props;
    const targetZoom = getMinZoomLevel();

    const newMapOptions = {
      minZoom: targetZoom,
    };

    setMapOptions(newMapOptions);
    this.map.setOptions(newMapOptions);

    this.removeBreadcrumbEvents();

    this.zoomOutOfChosenRegion();
  };

  zoomOutOfChosenRegion = (currentZoomLevel = this.map.getZoom()) => {
    const { google } = this.props;

    const googleMaps = google.maps;

    const targetZoom = getMinZoomLevel();

    if (currentZoomLevel > targetZoom) {
      googleMaps.event.addListenerOnce(this.map, 'zoom_changed', () => {
        this.zoomOutOfChosenRegion(currentZoomLevel - 1);
      });

      const delay =
        currentZoomLevel === targetZoom
          ? ANIMATION_LAST_STEP_DELAY
          : ANIMATION_DELAY;

      setTimeout(() => {
        this.map.setZoom(currentZoomLevel);
      }, delay);

      return;
    }

    this.moveToChosenRegion();
  };

  animateMapZoom = (targetZoomLevel, currentZoomLevel = this.map.getZoom()) => {
    const { google } = this.props;

    const googleMaps = google.maps;

    if (currentZoomLevel <= targetZoomLevel) {
      googleMaps.event.addListenerOnce(this.map, 'zoom_changed', () => {
        this.animateMapZoom(targetZoomLevel, currentZoomLevel + 1);
      });

      const delay =
        currentZoomLevel === targetZoomLevel
          ? ANIMATION_LAST_STEP_DELAY
          : ANIMATION_DELAY;

      setTimeout(() => {
        this.map.setZoom(currentZoomLevel);
      }, delay);

      return;
    }
    this.setNewMapOptions();
  };

  moveToChosenRegion = () => {
    const {
      google: { maps },
      chosenRegion,
      mapOptions: { center: currentCenter },
    } = this.props;
    const { chosenRegionCenter } = getChosenRegionData(chosenRegion);
    const chosenRegionZoomLevel = getMinZoomLevel(chosenRegion);

    if (this.map) {
      if (chosenRegion === riyadhRegionString) {
        const center = new maps.LatLng(
          chosenRegionCenter.lat,
          chosenRegionCenter.lng,
        );
        this.map.panTo(center);

        this.animateMapZoom(chosenRegionZoomLevel);
      } else {
        const currentZoomLevel = this.map.getZoom();
        const STEPS_NUMBER = Math.abs(chosenRegionZoomLevel - currentZoomLevel);

        const latDelta =
          (chosenRegionCenter.lat - currentCenter.lat) / STEPS_NUMBER;
        const lngDelta =
          (chosenRegionCenter.lng - currentCenter.lng) / STEPS_NUMBER;

        this.animateMoveToChosenRegion(chosenRegionCenter, currentCenter, {
          lng: lngDelta,
          lat: latDelta,
        });
      }
    }
  };

  onBoundChange = () => {
    const { map } = this;
    const {
      actions: { setMapRange },
    } = this.props;

    setMapRange(getBoundsFromMap(map));
  };

  fetchDataByBreadcrumb = (eventName) => {
    const { preventDefault } = mapEventState;

    const { getMapEventState, map, props } = this;

    const {
      actions: { getBreadcrumbData, getGroups },
    } = props;

    if (
      eventName === googleMapEvents.ZOOM_CHANGED &&
      getMapEventState(this.mapEventCallCounter) === preventDefault
    ) {
      return;
    }

    const bounds = getBoundsFromMap(map);
    const zoom = map.getZoom();
    const center = map.getCenter();
    const areaLat = center.lat();
    const areaLng = center.lng();
    const areaCenter = {
      latitude: areaLat,
      longitude: areaLng,
    };

    getBreadcrumbData(bounds, areaCenter, zoom);
    getGroups();

    if (eventName) {
      this.mapEventCallCounter += 1;
    }
  };

  removeBreadcrumbEvents = () => {
    this.googleMaps.event.removeListener(this.breadcrumbEvents.zoomChanged);
    this.googleMaps.event.removeListener(this.breadcrumbEvents.centerChanged);
    this.googleMaps.event.removeListener(this.breadcrumbEvents.idle);
    this.googleMaps.event.removeListener(
      this.breadcrumbEvents.mapTypeIdChanged,
    );

    this.breadcrumbEvents = {};
  };

  initBreadcrumbListeners = () => {
    const { fetchDataByBreadcrumb } = this;

    const {
      actions: { redrawMarkersAfterMapTypeIdChange },
    } = this.props;

    fetchDataByBreadcrumb();

    this.breadcrumbEvents.zoomChanged = this.googleMaps.event.addListener(
      this.map,
      googleMapEvents.ZOOM_CHANGED,
      debounce(EVENTS_DEBOUNCE_TIME, () =>
        fetchDataByBreadcrumb(googleMapEvents.ZOOM_CHANGED),
      ),
    );

    this.breadcrumbEvents.centerChanged = this.googleMaps.event.addListener(
      this.map,
      googleMapEvents.CENTER_CHANGED,
      debounce(EVENTS_DEBOUNCE_TIME, () =>
        fetchDataByBreadcrumb(googleMapEvents.CENTER_CHANGED),
      ),
    );

    this.breadcrumbEvents.boundsChanges = this.googleMaps.event.addListener(
      this.map,
      googleMapEvents.BOUNDS_CHANGED,
      debounce(EVENTS_DEBOUNCE_TIME, () => this.onBoundChange()),
    );

    this.breadcrumbEvents.idle = this.googleMaps.event.addListener(
      this.map,
      googleMapEvents.IDLE,
      debounce(EVENTS_DEBOUNCE_TIME, () => {
        this.mapEventCallCounter = 0;
      }),
    );

    this.breadcrumbEvents.mapTypeIdChanged = this.googleMaps.event.addListener(
      this.map,
      googleMapEvents.MAP_TYPE_ID_CHANGED,
      () => {
        const { areaShapes } = this.props;

        this.setMapType(this.map);
        setMapShape(areaShapes);
        redrawMarkersAfterMapTypeIdChange();
      },
    );
  };

  getMapEventState = (counter) => {
    if (counter > 0) {
      return mapEventState.preventDefault;
    }

    return mapEventState.default;
  };

  locateMeHandler = (coords) => {
    const {
      actions: { setCurrentLocation, setMapZoom },
    } = this.props;
    setCurrentLocation(coords);
    setMapZoom(16);
  };

  setMapType = (map) => {
    const {
      actions: { setMapType },
    } = this.props;
    const { mapTypeId } = map;

    setMapType(mapTypeId);
  };

  loadMap() {
    const { google, mapOptions } = this.props;
    if (this.props && google) {
      const googleMaps = google.maps;

      // eslint-disable-next-line react/no-string-refs
      const mapRef = this.refs.map;
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDOM.findDOMNode(mapRef);

      const center = new googleMaps.LatLng(mapOptions.center);
      const mapConfig = {
        center,
        zoom: mapOptions.minZoom,
        streetViewControl: mapOptions.streetViewControl,
        zoomControl: mapOptions.zoomControl,
        gestureHandling: mapOptions.gestureHandling,
        scrollwheel: mapOptions.scrollwheel,
        fullscreenControl: false,
        mapTypeControlOptions: {
          position: googleMaps.ControlPosition.LEFT_BOTTOM,
          style: googleMaps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: [
            googleMaps.MapTypeId.ROADMAP,
            googleMaps.MapTypeId.HYBRID,
          ],
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        controlSize: 21,
        styles: MapViewStyles,
      };

      this.map = new googleMaps.Map(node, mapConfig);

      let preventSingleClickHandler = false;

      googleMaps.event.addListener(this.map, 'click', () => {
        const activeSidebarArrows = document.getElementsByClassName(
          'SidebarItem__icon-container--active',
        );
        setTimeout(() => {
          if (activeSidebarArrows.length && !preventSingleClickHandler) {
            activeSidebarArrows[0].click();
          }
          preventSingleClickHandler = false;
        }, 300);
      });

      googleMaps.event.addListener(this.map, 'dblclick', (event) => {
        preventSingleClickHandler = true;
        if (isAdmin(store.getState())) {
          const latitude = event.latLng.lat();
          const longitude = event.latLng.lng();
          const newBusinessLink = `${CONFIG.api}/admin/create-business?latitude=${latitude}&longitude=${longitude}`;
          window.open(newBusinessLink, '_blank');
        }
      });

      const { set } = googleMaps.InfoWindow.prototype;

      const closeInfoWindow = (e) => {
        const clickedElement = e.target;
        const isMarkerClicked = clickedElement.classList.contains('marker');

        if (isMarkerClicked) return;

        const isCloseButtonClicked =
          clickedElement.classList &&
          clickedElement.classList.contains('geo-icon-cancel');

        const isInfoWindowContainerClicked =
          clickedElement.classList &&
          (clickedElement.classList.contains('info-window__container') ||
            clickedElement.classList.contains('infoWindow'));

        const isInfoWindowTextClicked =
          (clickedElement.parentElement.offsetParent &&
            clickedElement.parentElement.offsetParent.classList.contains(
              'info-window__container',
            )) ||
          (clickedElement.offsetParent &&
            clickedElement.offsetParent.classList.contains(
              'info-window__container',
            ));

        const isInfoWindowClicked =
          isInfoWindowTextClicked || isInfoWindowContainerClicked;

        if (isCloseButtonClicked || !isInfoWindowClicked) {
          const {
            actions: { setInfoWindowOnMap },
          } = this.props;
          setInfoWindowOnMap(null);
        }
      };

      document.body.addEventListener('click', closeInfoWindow);
      document.body.addEventListener('touchstart', closeInfoWindow);

      googleMaps.InfoWindow.prototype.set = function updatedSet(key) {
        if (key === 'map' && !this.get('noSuppress')) {
          return;
        }

        // eslint-disable-next-line prefer-rest-params
        set.apply(this, arguments);
      };

      const { setMap, actions } = this.props;

      setMap(this.map);
      actions.setMap(this.map);

      window.addEventListener(
        'orientationchange',
        this.handleOrientationChange.bind(this),
      );

      this.googleMaps.event.addListener(
        this.map,
        debounce(EVENTS_DEBOUNCE_TIME, 'zoom_changed', () => {
          actions.setMapZoom(this.map.getZoom());
        }),
      );

      this.googleMaps.event.addListener(this.map, 'dragstart', () => {
        const [infoContainer] = document.getElementsByClassName('gm-style-pbc');

        if (!infoContainer) {
          return;
        }

        const markersContainer = infoContainer.nextSibling;

        markersContainer.style.zIndex = 1;
      });

      this.googleMaps.event.addListener(this.map, 'dragend', () => {
        const [infoContainer] = document.getElementsByClassName('gm-style-pbc');

        if (!infoContainer) {
          return;
        }

        const markersContainer = infoContainer.nextSibling;

        const mapInfoAnimationTime = 500;

        setTimeout(() => {
          markersContainer.style.zIndex = 3;
        }, mapInfoAnimationTime);
      });

      googleMaps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        setTimeout(() => {
          this.setState({ loader: false });
        }, 500);
      });
    }
  }

  recenterMap() {
    const {
      mapOptions,
      google: { maps },
    } = this.props;
    const curr = mapOptions.center;

    if (this.map) {
      const center = new maps.LatLng(curr.lat, curr.lng);
      this.map.panTo(center);
    }
  }

  changeZoom(zoomLevel) {
    this.map.setZoom(zoomLevel);
  }

  render() {
    const {
      runMapExtensions,
      actions: { drawMarkers },
      businesses,
    } = this.props;

    const { loader } = this.state;

    return (
      <div id='map-wrapper' className='map__container'>
        <OnboardingContainer isMapLoaded={!loader} section='map' />
        {loader && <MapLoader />}

        {/* eslint-disable-next-line */}
        <div ref='map' className='map' />
        {runMapExtensions && (
          <MapExtensionsContainer locateMeHandler={this.locateMeHandler} />
        )}

        {runMapExtensions && (
          <MapMarkers
            businesses={businesses}
            drawMarkers={() => drawMarkers(store)}
          />
        )}
      </div>
    );
  }
}

Map.propTypes = {
  google: PropTypes.object,
  businesses: PropTypes.object,
  mapOptions: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    mapTypeId: PropTypes.string,
    zoom: PropTypes.number,
    minZoom: PropTypes.number,
    streetViewControl: PropTypes.bool,
    zoomControl: PropTypes.bool,
    viewport: PropTypes.object,
    gestureHandling: PropTypes.string,
    scrollwheel: PropTypes.bool,
  }),
  clusters: PropTypes.shape({
    enabled: PropTypes.bool,
    areaType: PropTypes.string,
  }),
  setMap: PropTypes.func,
  actions: PropTypes.shape({
    setUser: PropTypes.func,
    getBusinesses: PropTypes.func,
    getSectorsSizes: PropTypes.func,
    setMapZoom: PropTypes.func,
    setMapRange: PropTypes.func,
    setCurrentLocation: PropTypes.func,
    getBreadcrumbData: PropTypes.func,
    getGroups: PropTypes.func,
    setMap: PropTypes.func,
    drawMarkers: PropTypes.func,
    setInfoWindowOnMap: PropTypes.func,
    setMapOptions: PropTypes.func,
    setUserPosition: PropTypes.func,
    setModalMessage: PropTypes.func,
    setMapType: PropTypes.func,
    toggleModalVisibility: PropTypes.func,
    redrawMarkersAfterMapTypeIdChange: PropTypes.func,
  }),
  runMapExtensions: PropTypes.bool,
  chosenRegion: PropTypes.string,
  areaShapes: PropTypes.object,
};

export default MapView;
